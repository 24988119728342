import React from "react";
import "./SetAppointments.css";

const SetAppointments = () => {
//	<img src={imageSrc} alt="" loading="lazy" height="100%" width="100%" />

	return (

	
					<a
						href={"https://calendly.com/yonatan888/30min"}
						className="button-link"
						target="_blank"
						rel="noreferrer noopener"
					>
						Set up a free 30 minute meeting with me
					</a>
	

	);
};

export default SetAppointments;
